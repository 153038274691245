import { Type } from 'class-transformer'
import { ActionActivity } from './ActionActivity'
import { BaseModel } from './BaseModel'
import { ContentActivity } from './ContentActivity'
import { ExamActivity } from './ExamActivity'
import { Phase } from './Phase'
import { Project } from './Project'
import { QuizActivity } from './QuizActivity'

export enum ActivityType {
  CONTENT = 'content',
  ACTION = 'action',
  QUIZ = 'quiz',
  JOURNAL = 'journal',
  EXAM = 'exam',
}

export type Activity =
  | ActionActivity
  | ContentActivity
  | ExamActivity
  | QuizActivity

export abstract class BaseActivity extends BaseModel {
  static getCreationRoute(project: Project, phase: Phase, type: ActivityType) {
    return `/proyectos/${project.id}/fases/${phase.id}/actividades/crear?type=${type}`
  }

  _id: string
  title: string
  hidden: boolean
  abstract type: ActivityType

  @Type(() => Date)
  startDate: Date

  @Type(() => Date)
  endDate: Date

  get id() {
    return this._id
  }

  abstract getTypeName(): string
}
