import { BaseContentItem, ContentItemType } from './ContentItem'
import i18n from '@/lib/i18n'

export class RichTextContentItem extends BaseContentItem {
  type = ContentItemType.RICH_TEXT as const
  editorContent: unknown

  getTypeName(): string {
    return i18n.t('content-activity:content.document')
  }
}
