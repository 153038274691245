import { isObject } from 'lodash'
import { keyBy } from 'lodash/fp'
import { useEffect, useMemo, useState } from 'react'
import {
  AxiosInstance,
  keys as impersonationKeys,
  TeacherFilters,
} from '../../contexts/impersonation'
import URLSearchParamsArray, {
  cloneAsRecord,
  UrlParams,
} from '../URLSearchParamsArray'
import { ConfigurationObject } from '@/lib/configuration'
import { useFetch } from '@/lib/http'
import { useInstitutionContext } from '@/lib/institution-context'
import { BaseModel } from '@models/BaseModel'
import { BidiServiceIntegration } from '@models/BidiServiceIntegration'
import { CohortWithCourses } from '@models/CohortWithCourses'
import { Configuration } from '@models/Configuration'
import { Institution } from '@models/Institution'
import { InstitutionCohorts } from '@models/InstitutionCohorts'
import { InstitutionModules } from '@models/InstitutionModules'
import { Jurisdiction } from '@models/Jurisdiction'
import { Level } from '@models/Level'
import { Subject } from '@models/Subject'
import { Teacher } from '@models/Teacher'

function useDataForEntity<T extends BaseModel>(
  entity: {
    new (...args): T
    fromData(data): T
    getPath(): string
  },
  params: UrlParams = {},
  keyField = 'id'
) {
  const [asList, setAsList] = useState<T[]>([])
  const [asString, setAsString] = useState<string>('')

  let url = entity.getPath()
  const otherParams = new URLSearchParamsArray(params)

  if (!otherParams.isEmpty()) {
    url += querystringSeparator(url) + otherParams.toString()
  }

  const { data, error } = useFetch<T[]>(url, {
    refreshInterval: 0,
  })

  useEffect(() => {
    if (data) {
      if (typeof data === 'string') {
        setAsString(data)
      } else {
        setAsList(data.map(d => entity.fromData(d)))
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity, data])

  const asDict = useMemo(() => keyBy(keyField, asList), [keyField, asList])

  return {
    loading: !data && !error,
    error,
    asList,
    asDict,
    asString,
  }
}

export const useJurisdictions = () => {
  return useDataForEntity<Jurisdiction>(Jurisdiction, { limit: '100' })
}

export const useInstitutions = () => {
  return useDataForEntity<Institution>(Institution)
}

export const usePlatformConfiguration = () => {
  const { selectedInstitution } = useInstitutionContext()

  const institutionId = selectedInstitution?.id

  const [asList, setAsList] = useState<Configuration[]>([])
  const { data, error } = useFetch<ConfigurationObject>(() =>
    institutionId ? Configuration.getPath(institutionId) : null
  )

  useEffect(() => {
    if (data && isObject(data)) {
      setAsList(Configuration.fromObject(data, institutionId ?? null))
    }
  }, [data, institutionId])

  return {
    loading: !data && !error,
    error,
    asDict: data ?? {},
    asList,
  }
}

// Este "get" usa internamente un post al ser una query al servicio de GraphQL
export const getInstitutionModules = async (
  institutionId: Institution['id'],
  axios: AxiosInstance
): Promise<InstitutionModules | null> => {
  let institution: InstitutionModules | null = null

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data } = await axios.post<any>(
    InstitutionModules.getPath(),
    {
      query: InstitutionModules.getQuery(institutionId),
    },
    {}
  )

  if (
    data &&
    data.data &&
    data.data.institution &&
    data.data.institution.id === institutionId
  ) {
    institution = InstitutionModules.fromData(data.data.institution)
  }

  return institution
}

export const useBidiServiceIntegration = (level: string) => {
  return useDataForEntity<BidiServiceIntegration>(BidiServiceIntegration, {
    level: level,
  })
}

export const useLevels = () => {
  const { selectedJurisdiction } = useInstitutionContext()
  return useDataForEntity<Level>(Level, {
    jurisdictionId: selectedJurisdiction?.id,
  })
}

export const useSubjects = () => {
  const { selectedInstitution } = useInstitutionContext()
  return useDataForEntity<Subject>(Subject, {
    institutionId: selectedInstitution?.id,
  })
}

export const useTeachers = (instituionId: string) => {
  const [asList, setAsList] = useState<Teacher[]>([])
  const { data, error } = useFetch<Teacher[]>(
    Teacher.getPathByInstitution(instituionId)
  )

  useEffect(() => {
    if (data) {
      setAsList(data.map(d => Teacher.fromData(d)))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data])

  return {
    loading: !data && !error,
    error,
    asList,
  }
}

export const useTeacher = (userId: string) => {
  const { data, error } = useFetch<Teacher[]>(Teacher.getPathByUser(userId))
  return {
    loading: !data && !error,
    error,
    teacher: data && data.length > 0 ? Teacher.fromData(data[0]) : null,
  }
}

// Este "get" usa internamente un post al ser una query al servicio de GraphQL
export const getInstitutionCohorts = async (
  institutionId: Institution['id'],
  axios: AxiosInstance
): Promise<InstitutionCohorts | null> => {
  let institutionCohorts: InstitutionCohorts | null = null

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data } = await axios.post<any>(
    InstitutionCohorts.getPath(),
    {
      query: InstitutionCohorts.getQuery(institutionId),
    },
    {}
  )

  if (
    data &&
    data.data &&
    data.data.institution &&
    data.data.institution.id === institutionId
  ) {
    institutionCohorts = InstitutionCohorts.fromData(data.data.institution)
  }

  return institutionCohorts
}

// Este "get" usa internamente un post al ser una query al servicio de GraphQL
export const getCohorts = async (
  axios: AxiosInstance,
  institutionId: Institution['id']
): Promise<CohortWithCourses[]> => {
  let cohorts: CohortWithCourses[] = []

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data } = await axios.post<any>(
    CohortWithCourses.getPath(),
    {
      query: CohortWithCourses.getQuery(institutionId),
    },
    {}
  )

  if (data && data.data && data.data.cohorts) {
    cohorts = data.data.cohorts.map(cohort =>
      CohortWithCourses.fromData(cohort)
    )
  }

  return cohorts
}

export const useImpersonations = (filters: TeacherFilters) => {
  const searchFields = cloneAsRecord(filters)
  const queryString = new URLSearchParamsArray(searchFields).toString()

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const { data, error } = useFetch<any[]>(
    () => `${impersonationKeys.impersonations()}?${queryString}`,
    {
      refreshInterval: 0,
    },
    true
  )

  const teachers = data ? data.map(t => Teacher.fromData(t)) : []

  return {
    loading: !data && !error,
    teachers: !!data && !error ? teachers : [],
    error,
  }
}

function querystringSeparator(path: string) {
  // TODO no funciona si el path original tuviera `?` escapados
  return path.includes('?') ? '&' : '?'
}
